import { useSx, View } from "dripsy"
import Layout from "../layout"
import { useCalculateFontSize } from "app/redux/hooks/use-calculate-font-size";
import ContactUs from "./contactus";
import HowWeWork from "./how-we-work";
import Aboutus from "./aboutus";
import ExploreOurFeatures from "./explore-our-features";
import ChooseGennoctua from "./choose-gennoctua";
import UserReview from "./user-reviews";
import OurBlog from "./our-blog";
import GennoctuaFooter from "./footer";
import Head from 'next/head';
import { useLayoutEffect } from "react";
import { useRouter } from "solito/router";
import { getClientConfig, getUser } from "app/redux/memoized-selectors";
import { useSelector } from "react-redux";
import { NavBar } from "../layout/navbar";



const HomePage = () => {
    const { clientConfig } = useSelector(getClientConfig);
    const sx = useSx();
    const getFontSize = useCalculateFontSize();
    const { replace } = useRouter();
    const { user } = useSelector(getUser);

    // useLayoutEffect(() => {
    //     console.log(user)
    //     if (user?._id) {
    //         replace('/dashboard')
    //     }
    // }, [user])

    return (
        <>
            {/* Add Head metadata for SEO */}
            <Head>
                <title>Designing Made Simple: Embrace AI Home Decor Tools for Effortless Styling</title>
                <meta name="description" content="Ready to elevate your home decor game? Discover how AI home decor tools are reshaping the way we design our spaces. With personalized recommendations and real-time visualizations, you can bring your dream home to life with ease and confidence. Let’s make your design journey exciting and stress-free!" />
                <meta name="keywords" content="AI home decor" />
                {/* <link rel="canonical" href="https://www.gennoctua.com" />   */}
                <meta property="og:title" content="Designing Made Simple: Embrace AI Home Decor Tools for Effortless Styling" />
                <meta property="og:description" content="Ready to elevate your home decor game? Discover how AI home decor tools are reshaping the way we design our spaces. With personalized recommendations and real-time visualizations, you can bring your dream home to life with ease and confidence. Let’s make your design journey exciting and stress-free!" />
                <meta property="og:url" content="https://www.gennoctua.com" />
                <meta property="og:type" content="website" />
            </Head>
            
          
  <Layout       
                isSidebarMenu={false}
                subContainerStyle={{ maxWidth: ['100%', '100%'] }}
                isProfileIcon={false}
                scrollEnabled={true}
                goToDashBoardButton
                containerStyle={sx({
                    paddingX: [0],
                    paddingY: [0],
                })}

                mainContainerStyle={sx({
                    width: '100%',
                    height: '100%',
                })}
                loginSignUpBtn
                logoPositionCenter={false}
            >
                
                <ContactUs />
                <Aboutus />
                <HowWeWork />
                <ExploreOurFeatures />
                <ChooseGennoctua />
                <UserReview />
                <OurBlog />
                <GennoctuaFooter />
            </Layout> 
        </>
    )
}

export default HomePage;

