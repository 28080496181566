import { Image, styled, Text, useSx, View } from 'dripsy'
import { useCalculateFontSize } from 'app/redux/hooks/use-calculate-font-size'
import { Button } from 'app/components/button'
import { Icon } from 'app/components/icon/icon'
import { useRouter } from 'solito/router'
import { useState } from 'react'
import { Platform } from 'react-native'
// import { useTokenAuth } from '../layout/navbar'

// const StyledView = styled(View);

const TitleText = styled(Text)({
  fontFamily: 'Merriweather-Bold',
})

const TextParagraph = styled(Text)({
  fontFamily: 'Montserrat',
})

const HowWeWork = () => {
  const list = [
    {
      title: 'Upload & Visualize',
      icon: require('../../assets/assistance.webp'),
      disc: 'Snap a picture of your space, and our AI will show you exactly where your furniture fits, making it easy to create the perfect layout without lifting a finger.',
    },
    {
      title: 'Smart Assembly Guidance',
      icon: require('../../assets/assembling.webp'),
      disc: 'Say goodbye to long, complicated manuals. Our AI converts them into step-by-step videos in your preferred language, helping you assemble like a pro.',
    },
    {
      title: 'Real-Time Assistance',
      icon: require('../../assets/efficiency.webp'),
      disc: 'Stuck? Our AI assistant will look at your project and provide instant voice-over guidance, ensuring everything is assembled just right.',
    },
  ]
  const sx = useSx()
  const getFontSize = useCalculateFontSize()
  const { push } = useRouter()
  // const { handleTokenAuth, loading } = useTokenAuth();
  return (
    <View
      style={sx({
        width: '100%',
        minHeight: ['50vh', '50vh', '60vh'],
        flexDirection: 'column',
        paddingX: ['5%', '5%', '8%'],
        backgroundColor: '#F8FBFA',
      })}
    >
      <View
        style={sx({
          width: '100%',
          height: 46,
          marginTop: [20, 0],
        })}
      >
        <TitleText
          style={sx({
            fontSize: getFontSize(100, 30),
            fontWeight: '700',
            textAlign: 'left',
            lineHeight: '46px',
            letterSpacing: '-0.02em',
            color: '$primary',
          })}
        >
          How We Work
        </TitleText>
      </View>
      <View
        style={sx({
          flexDirection: ['column', 'column', 'row'],
          flex: 1,
          height: 'calc(100% - 46px)',
          marginTop:8,
          marginBottom:8
        })}
      >
        {list.map((item, index) => {
          return <Card item={item} index={index} key={index} />
        })}
      </View>
      <View
        style={sx({
          width: '100%',
          justifyContent: 'center',
          alignItems: ['flex-start', 'flex-start', 'center'],
        })}
      >
        <TitleText
          style={sx({
            fontSize: getFontSize(70, 20),
            fontWeight: '700',
            textAlign: 'left',
            lineHeight: '46px',
            letterSpacing: '-0.02em',
          })}
        >
          Try Our AI Tools for Free
        </TitleText>
        <View
          style={sx({
            height: [10, 20],
          })}
        ></View>
        <Button
          style={sx({
            height: 40,
            maxWidth: 350,
            minWidth: [200, 250],
            backgroundColor: '#2D1D1D',
            padding: 10,
          })}
          textStyle={{
            fontSize: 14,
            fontFamily: 'Montserrat',
          }}
          onPress={() => {
            push('/dashboard')
            // handleTokenAuth()
          }}
          // loading={loading}
          endIcon={
            <Icon
              name={'arrow'}
              fill={['#FFF']}
              style={{
                transform: [{ rotate: '90deg' }],
              }}
            />
          }
        >
          Upload Your First Image
        </Button>
      </View>
    </View>
  )
}
export default HowWeWork


const Card=({ item,index })=>{
  const [isHovered, setIsHovered] = useState(false);
  const sx = useSx()
  const getFontSize = useCalculateFontSize()
  return  <View
  {...(Platform.OS === 'web' ? {
    onMouseEnter: () => setIsHovered(true),
    onMouseLeave: () => setIsHovered(false)
  } : {})}
    key={`${index}-index-data`}
    style={[sx({
      height: [190, 300, 350],
      width: ['100%', '100%', '33.33%'],
      padding: [0, 0, '5%'],
      flexDirection: ['column'],
      // backgroundColor: '#FFF', // Default background
      cursor:'pointer',
      transition: 'background-color 0.3s ease', // Smooth transition
      backgroundColor: isHovered ? '#FFF':'#F8FBFA' ,
      justifyContent:'center'
    })
  ]}
  >
    <Image
      resizeMode={'contain'}
      style={[
        sx({
          width: ['50px', '146px'],
          height: ['50px', '146px'],
          objectFit: 'contain',
        }),
      ]}
      source={item.icon}
      resizeMethod={'scale'}
      alt={item?.icon || ''}
    />
    <View style={{ height: 10 }} />
    <TextParagraph
      style={sx({
        fontSize:  24,
        fontWeight: '700',
        textAlign: 'left',
        color: '#030605',
        marginBottom: 5,
      })}
    >
      {item.title}
    </TextParagraph>
    <TextParagraph
      style={sx({
        fontSize:  16,
        fontWeight: '500',
        textAlign: 'left',
        lineHeight: '24px',
        color: '#5C5E5E',
      })}
    >
      {item.disc}
    </TextParagraph>
  </View>
}
